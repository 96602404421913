import { IQueryMany, IQueryOne } from '../interfaces';
import { serializeFilters } from '../utils';

export const QUERIES = {
  LOCATION: {
    getLatestCollections(): IQueryMany {
      return {
        select: 'slug,name,id,organization,type,updatedAt',
        filter: `type==collection`,
        page: { size: 5 },
        sort: '-updatedAt',
      };
    },

    getFiltered(search: string, filters): IQueryMany {
      return {
        search,
        filter: serializeFilters(filters),
        select: 'name,slug,organization,type',
        sort: 'name',
      };
    },

    getExceptCollectionForOrg(
      search: string,
      org: string,
      includePublic: boolean = false
    ): IQueryMany {
      return {
        search,
        filter: `type!=Collection`,
        select: 'name,slug,organization,type',
        sort: 'name',
        group: org,
        public: includePublic,
      };
    },

    getFeatured(): IQueryMany {
      return {
        select: 'slug,name,id,organization,type',
        page: { size: 100 },
        filter: 'featured==true',
        sort: 'name',
      };
    },

    getCollection(group: string): IQueryOne {
      return {
        include: 'locations,metrics',
        select: 'locations.slug,locations.name',
        group,
      };
    },

    getOne(group): IQueryOne {
      return {
        include: 'metrics',
        group,
      };
    },

    getOneWithoutGeoJSON(group): IQueryOne {
      return {
        include: 'metrics',
        select: '-geojson',
        group,
      };
    },
  },

  DASHBOARD: {
    getAllFiltered(search: string): IQueryMany {
      return {
        search,
        sort: 'name',
        select: 'name,slug',
      };
    },
    getWithWidgets(): IQueryOne {
      return {
        include: ['widgets', 'widgets.layers', 'widgets.layers.references'].join(','),
      };
    },
  },

  LAYER: {
    getActive(slugs: string): IQueryMany {
      if (!slugs) {
        // prevent SWR from fetching
        return null;
      }

      return {
        filter: serializeFilters({
          slug: slugs,
        }),
        // can we remove this?
        include: 'references',
      };
    },

    getPrimaryFiltered(search: string, filters): IQueryMany {
      return {
        search,
        sort: 'name',
        filter: serializeFilters({
          ...filters,
          primary: true,
        }),
        include: 'references',
      };
    },

    getAllFiltered(search: string, query?: { [key: string]: any }): IQueryMany {
      return {
        search,
        sort: 'name',
        select: 'name,slug',
        ...query,
      };
    },

    getForExport(search: string): IQueryMany {
      const primaryFilter = serializeFilters({
        primary: true,
        provider: 'IO STAC Service',
      });

      const licenseRestrictionFilter = ['licenseRestriction', '!=', true].join('');

      return {
        search,
        filter: [primaryFilter, licenseRestrictionFilter].join(','),
        include: 'references',
        select:
          'name,organization,config,references.name,references.config,references.licenseRestriction,references.organization',
        sort: 'name',
      };
    },
  },

  ANALYSIS: {
    getAll(): IQueryMany {
      return {};
    },
    getAllFiltered(group, type = ''): IQueryMany {
      return {
        group,
        filter: type ? `type==${type}` : '',
        sort: 'id',
        select: 'name,slug,location,config',
      };
    },
    getOne(group): IQueryOne {
      return {
        group,
      };
    },
    getLatest(type = ''): IQueryMany {
      return {
        select: 'slug,name,id,location,organization,type,status,task,result,updatedAt',
        include: 'location',
        filter: type ? `type==${type}` : '',
        sort: '-updatedAt',
      };
    },
  },

  WIDGET: {
    getAllFiltered(search: string): IQueryMany {
      return {
        search,
        sort: 'name',
        select: 'name,slug',
      };
    },
  },

  WORKSPACE: {
    getAll(): IQueryMany {
      return {
        sort: 'name',
        select: 'name,slug',
      };
    },

    getOne(): IQueryOne {
      return {
        include: 'owners',
      };
    },
  },

  USER: {
    getAll() {
      return {
        include: 'groups',
      };
    },
  },

  ZONAL_METRICS: {
    getAll(): IQueryMany {
      return {
        sort: 'name',
        select: 'name,slug',
      };
    },
    getAllFiltered(search: string): IQueryMany {
      return {
        search,
        sort: 'name',
        select: 'name,slug',
      };
    },
  },
};
