module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://06d51080bdaa4fba882014b513aef5e0@o1192228.ingest.sentry.io/6313811","environment":"IO Sidekick","release":"743a0355ad44885741691e28c3a2df4593ac8fbb","sampleRate":1,"ignoreErrors":["@math.gl/web-mercator: assertion failed","Fetch is aborted","NS_ERROR_FILE_NO_DEVICE_SPACE","ResizeObserver loop limit exceeded","ResizeObserver loop completed with undelivered notifications."]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-CZCZ6XS493"],"gtagConfig":{},"pluginConfig":{"head":true,"respectDNT":true,"exclude":[]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T7DZDN5","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
